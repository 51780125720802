<template>
    <td
        :class="breakpointStyles"
        class="w-full px-2 sm:px-3 py-3 text-sm text-gray-500 dark:text-gray-200"
        v-bind="$attrs">
        <slot />
    </td>
</template>

<script setup>
const props = defineProps({
    mobileColumn: {
        type: Boolean,
        default: false
    },
    breakpoint: {
        type: String,
        default: 'sm'
    }
})

const {getBreakpointStyles} = useListingFeatures()
const breakpointStyles = computed(() => getBreakpointStyles(props.mobileColumn, props.breakpoint))
</script>

<style scoped>

</style>