export const useListingFeatures = () => {

    const getBreakpointStyles = (mobile = false, breakpoint = 'sm') => {
        if (mobile) return ''
        switch (breakpoint) {
            case 'xs':
                return 'hidden xs:table-cell'
            case 'md':
                return 'hidden md:table-cell'
            case 'lg':
                return 'hidden lg:table-cell'
            case 'xl':
                return 'hidden xl:table-cell'
        }
        return 'hidden sm:table-cell'
    }

    return {
        getBreakpointStyles,
    };
};
