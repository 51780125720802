<template>
    <tr
        v-for="i in 10"
        :key="'loading-row' + i">
        <BaseListingValue
            class="sm:hidden"
            mobile-column
            :colspan="colspans['mobile']">
            <div class="animate-pulse">
                <div class="h-2 bg-neutral-200 rounded" />
            </div>
        </BaseListingValue>
        <BaseListingValue
            class="hidden sm:table-cell md:hidden"
            mobile-column
            :colspan="colspans['sm']">
            <div class="animate-pulse">
                <div class="h-2 bg-neutral-200 rounded" />
            </div>
        </BaseListingValue>
        <BaseListingValue
            class="hidden md:table-cell lg:hidden"
            mobile-column
            :colspan="colspans['md']">
            <div class="animate-pulse">
                <div class="h-2 bg-neutral-200 rounded" />
            </div>
        </BaseListingValue>
        <BaseListingValue
            class="hidden lg:table-cell xl:hidden"
            mobile-column
            :colspan="colspans['lg']">
            <div class="animate-pulse">
                <div class="h-2 bg-neutral-200 rounded" />
            </div>
        </BaseListingValue>
        <BaseListingValue
            class="hidden xl:table-cell"
            mobile-column
            :colspan="colspans['all']">
            <div class="animate-pulse">
                <div class="h-2 bg-neutral-200 rounded" />
            </div>
        </BaseListingValue>
    </tr>
</template>

<script setup>
/* eslint-disable no-fallthrough */
const props = defineProps({
    columns: {
        type: Array,
        required: true
    }
})

// TODO optimize or replace with better component

const colspans = computed(() => {
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    const mobileColumns = props.columns?.filter(c => c.mobile).length ?? 0

    const colspans = {
        mobile: mobileColumns,
        xs: mobileColumns,
        sm: mobileColumns,
        md: mobileColumns,
        lg: mobileColumns,
        xl: mobileColumns,
        all: props.columns.length
    }
    
    props.columns.forEach(column => {
        breakpoints.forEach((breakpoint, index) => {
            if (!column.mobile && (breakpoints.indexOf(column.breakpoint ?? 'sm') <= index)) {
                colspans[breakpoint]++
            }
        })
    })
    return colspans
})
</script>

<style scoped>

</style>