<template>
    <th
        scope="col"
        :class="[breakpointStyles, widthStyles, {'sticky top-0 bg-white border-b border-gray-300 bg-opacity-75 backdrop-blur backdrop-filter' : stickyHeader}]"
        class="py-3.5 pl-2 pr-2  sm:pl-3 sm:pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-50">
        <span
            class="group flex items-center"
            :class="{'cursor-pointer': column.sortable}"
            @click="column.sortable ? emits('orderBy', column.sortBy || column.name) : null">
            <Icon
                v-if="column.icon"
                :title="columnHeaderLabel"
                :name="column.icon" />
            <span
                v-else
                :title="columnHeaderLabel"
                class="truncate">
                {{ columnHeaderLabel }}
            </span>
            <span
                v-if="column.sortable"
                class="ml-2 flex-none rounded text-gray-400 dark:text-gray-200 group-hover:visible"
                :class="{'visible text-gray-800': isOrderColumn, 'invisible': !isOrderColumn }">
                <Icon
                    v-if="isOrderColumn && orderDirection === 'desc'"
                    name="heroicons:chevron-down"
                    class="w-3 h-3" />
                <Icon
                    v-else
                    name="heroicons:chevron-up"
                    class="w-3 h-3" />
            </span>
        </span>
    </th>
</template>

<script setup>

const props = defineProps({
    column: {
        type: Object,
        required: true
    },
    orderBy: {
        type: String,
        default: null
    },
    orderDirection: {
        type: String,
        default: null
    },
    mobileColumn: {
        type: Boolean,
        default: false
    },
    stickyHeader: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits(['orderBy'])

const module = inject('module', null)
const {t: $t} = useI18n()
const columnHeaderLabel = computed(() => {
    if (props.column?.label) return props.column?.label
    if (module) return $t(module + '.table.' + props.column?.name)
    return ''
})

const isOrderColumn = computed(() => {
    return props.orderBy === (props.column.sortBy || props.column.name)
})

const {getBreakpointStyles} = useListingFeatures()
const breakpointStyles = computed(() => getBreakpointStyles(props.column?.mobile, props.column?.breakpoint))

const widthStyles = computed(() => {
    if (!props.column?.width) return 'w-auto'
    return props.column.width
})
</script>

<style scoped>

</style>