<template>
    <table
        class="table-fixed w-full divide-y divide-neutral-200 dark:divide-gray-600"
        :class="{'border-separate border-spacing-0':stickyHeader}">
        <thead>
            <tr>
                <template v-for="column in columns">
                    <slot
                        :name="'header_' + column.name"
                        v-bind="column">
                        <BaseListingHeader
                            :key="'column-header-' + column.name"
                            :sticky-header="stickyHeader"
                            :column="column"
                            :order-by="actualOrderBy"
                            :class="column.headerClass"
                            :order-direction="actualOrderDirection"
                            @order-by="handleOrderBy" />
                    </slot>
                </template>
            </tr>
        </thead>
        <tbody class="divide-y divide-neutral-100 dark:divide-gray-500 bg-white dark:bg-gray-700">
            <template v-if="!loading">
                <tr
                    v-for="(row, index) in sortedData"
                    :key="'table-row-' + index"
                    class="group hover:bg-primary-50 dark:hover:bg-primary-900"
                    :class="[
                        {'odd:bg-gray-50 dark:odd:bg-gray-600' : style==='zebraStriping'},
                        {'bg-gray-50 dark:bg-gray-600' : !row.parent_funding_program_id && style ==='highlightParent'},
                        {'bg-white dark:bg-gray-800' : style === 'default'}
                    ]">
                    <BaseListingValue
                        v-for="column in columns"
                        :key="column.name"
                        :class="[{'cursor-pointer group-hover:text-primary-500': column.pageLink}, {'border-b border-gray-200' : stickyHeader && index < data.length - 1}, column.cellClass]"
                        :sticky-header="stickyHeader"
                        :mobile-column="column.mobile"
                        :breakpoint="column.breakpoint">
                        <slot
                            :name="column.name"
                            v-bind="row">
                            <div class="truncate">
                                {{ row[column.name] }}
                            </div>
                        </slot>
                    </BaseListingValue>
                </tr>
            </template>
            <template v-else>
                <BaseLoadingRows
                    :columns="columns" />
            </template>
        </tbody>
    </table>
</template>

<script setup>

const props = defineProps({
    data: {
        type: Array,
        required: true
    },
    columns: {
        type: Array,
        required: true
    },
    loading: {
        type: Boolean,
        default: false
    },
    sortInPlace: {
        type: Boolean,
        default: false
    },
    orderBy: {
        type: String,
        default: null
    },
    orderDirection: {
        type: String,
        default: null
    },
    style: {
        type: String,
        default: 'zebraStriping',
        description: '[zebraStriping, highlightParent, default]'
    },
    stickyHeader: {
        type: Boolean,
        default: false
    }
})

const emits = defineEmits([
    'orderBy'
])

const localOrderBy = ref(null)
const localOrderDirection = ref('desc')

const actualOrderBy = computed(() => props.sortInPlace ? localOrderBy.value : props.orderBy)
const actualOrderDirection = computed(() => props.sortInPlace ? localOrderDirection.value : props.orderDirection)

const sortedData = computed(() => {
    if (!props.sortInPlace) return props.data

    return props.data.slice().sort((a, b) => {
        if (a[actualOrderBy.value] > b[actualOrderBy.value]) {
            return actualOrderDirection.value === 'asc' ? 1 : -1
        }
        if (a[actualOrderBy.value] < b[actualOrderBy.value]) {
            return actualOrderDirection.value === 'asc' ? -1 : 1
        }
        return 0
    })
})

function handleOrderBy(column) {
    if (!props.sortInPlace) {
        emits('orderBy', column)
        return
    }

    if (localOrderBy.value === column) {
        if (localOrderDirection.value === 'asc')
            localOrderDirection.value = 'desc'
        else {
            localOrderBy.value = null
            localOrderDirection.value = 'desc'
        }
    } else {
        localOrderBy.value = column
        localOrderDirection.value = 'asc'
    }
}
</script>

<style scoped>

</style>